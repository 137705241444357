function BankSelectValidation(value){
    let err_ = {};

    if (value.bankAccount === "") {
        err_.bankAccount = "Please select a valid Bank Account";
    } else {
        err_.bankAccount = "";
    }

    return err_;
}

export default BankSelectValidation;