function LoginValidation(value, type){
    let error_ = {};

    if (value.dbsuname === "") {
        error_.dbsuname = "Please provide User Id";
    } else {
        error_.dbsuname = "";
    }

    if (value.dbspin === "") {
        error_.dbspin = "Please provide PIN";
    } 
    else if (type === 1 && value.dbspin.length != 6) {
    error_.dbspin = "Please enter a valid 6 digit PIN";
    } 
    else {
        error_.dbspin = "";
    }

    return error_;
}

export default LoginValidation;