import { useState, useEffect } from 'react';
import '../DBS/Success.css';
import axios from 'axios';

function UOBSuccess(){
//----------------------------------------------------------------------------------------------------
    const ticketid_ = sessionStorage.getItem('TicketID');
    const getAmt = sessionStorage.getItem('Amount');
    // useEffect(()=>{
    //     axios
    //     .get('https://aranpay.onrender.com/uob/getcallback', {
    //         params: {
    //             TicketID: ticketid_
    //         }
    //     })
    //     .then(res => {
    //         console.log(res);
    //         const callbackURL = res.data[0].callbackURL;
    //         const userID = res.data[0].Username;
    //         const order = res.data[0].orderNo;

    //         const successData = {
    //             userID: userID,
    //             amount: getAmt,
    //             transactionID: ticketid_,
    //             orderNo: order,
    //             status: "Success"
    //         };

    //         let fee = 0.023 * parseFloat(getAmt);
    //         fee = fee.toFixed(2);
    //         console.log('fee: ', fee);

    //         const successData2 = {
    //             deposit: getAmt, 
    //             TicketID: ticketid_,
    //             type: 'Completed deposit',
    //             fee: fee
    //         };

    //         const urlEncodedData = new URLSearchParams(successData).toString();
    //         console.log(urlEncodedData);

    //         axios
    //             .post(callbackURL, urlEncodedData, {
    //                 headers: {
    //                     'Content-Type': 'application/x-www-form-urlencoded'
    //                 }
    //             })
    //             .then(res => {
    //                 console.log(res);
    //             })
    //             .catch(err => {
    //                 console.log(err);
    //             });
    //         axios
    //             .post('https://aranpay.onrender.com/settlement-balance', successData2)
    //             .then(res => {
    //                 console.log(res);
    //             })
    //             .catch(err => {
    //                 console.log(err);
    //             });
    //     })
    //     .catch( err => {
    //         console.log(err);
    //     });
    // }, [])
//----------------------------------------------------------------------------------------------------

    const handleSubmit_ = (e) => {
        e.preventDefault();
        let ticketid = sessionStorage.getItem('TicketID');
        axios
            .get('https://aranpay.onrender.com/uob/redirect', {
                params: {
                    TicketID : ticketid,
                }
            })
            .then(res => {
                console.log(res);
                const link = res.data[0].Domain;
                console.log(link);
                sessionStorage.clear();
                window.location.replace(link);
            })
            .catch(err => {
                console.log(err);
            });   
    };

    //countdown timer:
    const [timerz, setTimerz] = useState(8);
    useEffect(()=> {
        const countdownz = timerz > 0 && setInterval(() => setTimerz(timerz - 1), 1000);
        if (timerz === 0) {
            let ticketid = sessionStorage.getItem('TicketID');
            axios
                .get('https://aranpay.onrender.com/uob/redirect', {
                    params: {
                        TicketID : ticketid,
                    }
                })
                .then(res => {
                    console.log(res);
                    const link = res.data[0].Domain;
                    console.log(link);
                    sessionStorage.clear();
                    window.location.replace(link);
                })
                .catch(err => {
                    console.log(err);
                });   
        };
        return () => clearInterval(countdownz);
    },[timerz])

    return(
        <>
            <div className="header-container">
                <div className="time-left-container">
                    <label className='time-left'>Time Remaining: {timerz} Seconds</label>
                </div>
            </div>
            <div className="body-container">
                <div className="body-1-container">
                    <div className="amount-deposited-container">
                        <label className='inner-text'>Amount: ${sessionStorage.getItem('Amount')}</label>
                    </div>
                    <div className="amount-deposited-container">
                        <label className='inner-text'>Order No.: {sessionStorage.getItem('TicketID')}</label>
                    </div>
                    <div className="amount-deposited-container">
                        <label className='inner-text'>Transaction Time: {sessionStorage.getItem('Datetime')}</label>
                    </div>
                </div>
                <div className="body-2-container">
                        <div className="processes2-container">
                            <div className='dummy-container'>
                                <img src={require('../../img/UOB-hiso-4.png')} className="hiso-6-img" alt="hiso-4-png"/>
                            </div>
                            <div className='dummy-container2'>
                                <span className='result-text'>Transaction Successful</span>
                            </div>
                        </div>
                </div>
                <div className='redirect-btn-container'>
                    <button type='submit' onClick={handleSubmit_}>REDIRECT NOW</button>
                </div>
                <div className='reminder-container'> {/* move to center */}
                        <div className='reminder-list-list'>
                            <h2>Reminder</h2>   
                            <ol className='reminder-list'>
                                <li className='reminder-list-point'>Kindly ensure your bank account has been activated for online payment processing.</li>
                                <li className='reminder-list-point'>Please avoid logging into your bank account from multiple locations while a transaction is in progress.</li>
                                <li className='reminder-list-point'>Please do not click on any submit button more than once.</li>
                                <li className='reminder-list-point'>Please do not refresh your browser.</li>
                                <li className='reminder-list-point'>Please take note of the order number in the event you need to contact our Customer Service.</li>
                            </ol>
                        </div>
                </div> 
            </div>
        </>
    );
}

export default UOBSuccess;