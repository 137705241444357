function CreateAccKeys(array) {
    let count = 1;
    const keys = []
    for (let i = 0; i < array.length; i++) {
        const header = 'account';
        const headerConcat = header + String(count);
        keys.push(headerConcat);
        count++;
    }
    return keys
}

export default CreateAccKeys;