import axios from "axios";
import { useEffect, useState } from "react";
import { redirect, useNavigate, useParams } from "react-router-dom";
import LoginValidation from "../../components/LoginValidation";
import "./Login.css";

function DbsLogin() {
  // let authenticated;
  const navigate_ = useNavigate();
  // var ticket2 = sessionStorage.getItem('TicketID');
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    if (loginValid) {
      setModal(!modal);
    }
  };

  useEffect(() => {
    let ticketid = sessionStorage.getItem("TicketID");
    axios
      .get("https://aranpay.onrender.com/getsystemstatus", {
        params: {
          TicketID: ticketid,
          Bank: "DBS",
        },
      })
      .then((res) => {
        console.log(res);
        const transactionStatus = res.data.Status;
        if (Number(transactionStatus) === 1) {
          sessionStorage.setItem(
            "Error",
            "We're currently undergoing maintenance. Please try again later"
          );
          navigate_("/dbs/unsuccessful");
        }
        const event = new CustomEvent("startPolling", {
          detail: { ticketNumber: ticketid }, // Pass any necessary data
        });
        window.dispatchEvent(event);
      })
      .catch((errors) => {
        console.log(errors);
      });
  }, []);

  //------------------------------------------------------------------------------------------------------
  function checkforBalanceError() {
    let ticketid = sessionStorage.getItem("TicketID");
    axios
      .get("https://aranpay.onrender.com/gettransactionstatus", {
        params: {
          TicketID: ticketid,
        },
      })
      .then((res) => {
        console.log("Check 1: ", res);
        const transactionStatus = res.data[0].Result;
        console.log("Status: ", transactionStatus);
        if (transactionStatus === "Fail") {
          const getAmt = sessionStorage.getItem("Amount");
          sessionStorage.setItem("Error", "Insufficient Deposit Amount");
          navigate_("/dbs/unsuccessful");
          // axios
          //     .get('https://aranpay.onrender.com/dbs/getcallback', {
          //         params: {
          //             TicketID: ticketid
          //         }
          //     })
          //     .then(res => {
          //         console.log(res);
          //         const callbackURL = res.data[0].callbackURL;
          //         const userID = res.data[0].Username;
          //         const order = res.data[0].orderNo;

          //         const failData = {
          //             userID: userID,
          //             amount: getAmt,
          //             transactionID: ticketid,
          //             orderNo: order,
          //             status: "Failed"
          //         };

          //         const urlEncodedData = new URLSearchParams(failData).toString();
          //         console.log(urlEncodedData);
          //         axios
          //             .post(callbackURL, urlEncodedData, {
          //                 headers: {
          //                     'Content-Type': 'application/x-www-form-urlencoded'
          //                 }
          //             })
          //             .then(res => {
          //                 console.log(res);
          //             })
          //             .catch(err => {
          //                 console.log(err);
          //             });
          //     })
          //     .catch( err => {
          //         console.log(err);
          //     });
          // axios
          //     .get('https://aranpay.onrender.com/dbs/redirect', {
          //         params: {
          //             TicketID : ticketid,
          //         }
          //     })
          //     .then(res => {
          //         console.log(res);
          //         const link = res.data[0].Domain;
          //         console.log(link);
          //         sessionStorage.clear();
          //         window.location.replace(link);
          //     })
          //     .catch(err => {
          //         console.log(err);
          //     });
          // alert('Insufficient Deposit Amount');
        }
      })
      .catch((errors) => {
        console.log(errors);
      });
  }

  useEffect(() => {
    checkforBalanceError();
  }, []);

  //------------------------------------------------------------------------------------------------------
  async function handleFocus() {
    const storedMessage = sessionStorage.getItem("AccountsDisplayEvent");
    if (storedMessage) {
      console.log("Restoring misses event from sessionStorage: ", storedMessage);

      const event = new Event("sessionStorageUpdated");
      window.dispatchEvent(event);
    } else {
      console.log("Checked missed event");
    }
  }

  useEffect(() => {
    // Listen for a custom event called 'sessionStorageUpdated'
    window.addEventListener("focus", handleFocus);
    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, []);
  //------------------------------------------------------------------------------------------------------
  async function checkForErrorInSS() {
    if (sessionStorage.getItem("ErrorEvent") !== null) {
      let ticket5 = sessionStorage.getItem("TicketID");
      console.log(ticket5);
      await axios
        .get("https://aranpay.onrender.com/geterrorlog", {
          params: {
            TicketID: ticket5,
          },
        })
        .then((res) => {
          let Err_log = res.data[0].ErrorLog.includes("Browser Error")
            ? "Unexpected Error has occured. Please try again"
            : res.data[0].ErrorLog;
          Err_log = res.data[0].ErrorLog.includes("Session Error")
            ? "It appears you're logged in from another device. Please log out and try again."
            : Err_log;
          sessionStorage.setItem("Error", Err_log);
          navigate_("/dbs/unsuccessful");
        })
        .catch((err) => {
          console.log(err);
          sessionStorage.setItem(
            "Error",
            "An unexpected error has occured. Please try again"
          );
          navigate_("/dbs/unsuccessful");
        });
      // const Error_log = sessionStorage.getItem("Error");
      // const errorData = {
      //     TicketID: ticket5,
      //     ErrorLog: 'Incorrect Login Info'
      // };
      // axios
      //     .put('https://aranpay.onrender.com/dbs/error-catching', errorData)
      //     .then(res => {
      //         console.log(res);
      //         let ticketid = sessionStorage.getItem('TicketID');
      //         const getAmt = sessionStorage.getItem('Amount');
      //         sessionStorage.setItem("Error", "Wrong login info");
      //         navigate_("/dbs/unsuccessful");
      //     })
      //     .catch(errors => {
      //         console.log(errors);
      //     });
    }
  }

  useEffect(() => {
    console.log("Check 0");
    checkForErrorInSS();

    // Listen for a custom event called 'sessionStorageUpdated'
    window.addEventListener("error", checkForErrorInSS);
    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("error", checkForErrorInSS);
    };
  }, [sessionStorage]);
  //------------------------------------------------------------------------------------------------------

  function checkForKey1InSessionStorage() {
    if (sessionStorage.getItem("AccountsDisplayEvent") !== null) {
      const accData = sessionStorage.getItem("AccountsDisplayEvent");
      let arrayData;
      console.log(accData);
      try {
        if (accData.includes("&")) {
          arrayData = accData.split("&");
        } else {
          arrayData = [accData];
        }
      } catch {
        arrayData = [accData];
      }

      console.log(arrayData);
      sessionStorage.setItem("AccountsDisplay", JSON.stringify(arrayData));
      navigate_("/dbs/bank-select");
    }
  }

  // function checkForKey1InSessionStorage() {
  //   if (sessionStorage.getItem("AccountsDisplayEvent") !== null) {
  //     let ticket2 = sessionStorage.getItem("TicketID");
  //     axios
  //       .get("https://aranpay.onrender.com/dbs/accountsdisplay", {
  //         params: {
  //           TicketID: ticket2,
  //         },
  //       })
  //       .then((res) => {
  //         console.log(res);
  //         // {AccountsDisplay: shit ton of str delimted by ,}
  //         const accData = res.data[0].AccountsDisplay;
  //         const authenticated = res.data[0].Authenticated;
  //         let arrayData;
  //         // console.log(accData);
  //         try {
  //           if (accData.includes("&")) {
  //             arrayData = accData.split("&");
  //           } else {
  //             arrayData = [accData];
  //           }
  //         } catch {
  //           arrayData = [accData];
  //         }

  //         console.log(arrayData);
  //         sessionStorage.setItem("AccountsDisplay", JSON.stringify(arrayData));

  //         console.log(authenticated);
  //         if (authenticated === 1) {
  //           navigate_("/dbs/bank-select");
  //         } else {
  //           //else redirect to home
  //           const errorData = {
  //             TicketID: sessionStorage.getItem("TicketID"),
  //             ErrorLog: "No Authentication detected",
  //           };
  //           axios
  //             .put("https://aranpay.onrender.com/dbs/error-catching", errorData)
  //             .then((res) => {
  //               console.log(res);
  //             })
  //             .catch((errors) => {
  //               console.log(errors);
  //             });

  //           let ticketid = sessionStorage.getItem("TicketID");
  //           const getAmt = sessionStorage.getItem("Amount");
  //           sessionStorage.setItem("Error", "No authentication detected");
  //           navigate_("/dbs/unsuccessful");
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         const errorData = {
  //           TicketID: sessionStorage.getItem("TicketID"),
  //           ErrorLog: err,
  //         };
  //         axios
  //           .put("https://aranpay.onrender.com/dbs/error-catching", errorData)
  //           .then((res) => {
  //             console.log(res);
  //           })
  //           .catch((errors) => {
  //             console.log(errors);
  //           });

  //         let ticketid = sessionStorage.getItem("TicketID");
  //         const getAmt = sessionStorage.getItem("Amount");
  //         sessionStorage.setItem("Error", err);
  //         navigate_("/dbs/unsuccessful");
  //         // axios
  //         //     .get('https://aranpay.onrender.com/dbs/getcallback', {
  //         //         params: {
  //         //             TicketID: ticketid
  //         //         }
  //         //     })
  //         //     .then(res => {
  //         //         console.log(res);
  //         //         const callbackURL = res.data[0].callbackURL;
  //         //         const userID = res.data[0].Username;
  //         //         const order = res.data[0].orderNo;

  //         //         const failData = {
  //         //             userID: userID,
  //         //             amount: getAmt,
  //         //             transactionID: ticketid,
  //         //             orderNo: order,
  //         //             status: "Failed"
  //         //         };

  //         //         const urlEncodedData = new URLSearchParams(failData).toString();
  //         //         console.log(urlEncodedData);
  //         //         axios
  //         //             .post(callbackURL, urlEncodedData, {
  //         //                 headers: {
  //         //                     'Content-Type': 'application/x-www-form-urlencoded'
  //         //                 }
  //         //             })
  //         //             .then(res => {
  //         //                 console.log(res);
  //         //             })
  //         //             .catch(err => {
  //         //                 console.log(err);
  //         //             });
  //         //     })
  //         //     .catch( err => {
  //         //         console.log(err);
  //         //     });

  //         // alert("Error1: " + err);

  //         // axios
  //         //     .get('https://aranpay.onrender.com/dbs/redirect', {
  //         //         params: {
  //         //             TicketID : ticketid,
  //         //         }
  //         //     })
  //         //     .then(res => {
  //         //         console.log(res);
  //         //         const link = res.data[0].Domain;
  //         //         console.log(link);
  //         //         sessionStorage.clear();
  //         //         window.location.replace(link);
  //         //     })
  //         //     .catch(err => {
  //         //         console.log(err);
  //         //     });
  //       });
  //   }
  // }

  useEffect(() => {
    checkForKey1InSessionStorage();

    // Listen for a custom event called 'sessionStorageUpdated'
    window.addEventListener(
      "sessionStorageUpdated",
      checkForKey1InSessionStorage
    );
    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener(
        "sessionStorageUpdated",
        checkForKey1InSessionStorage
      );
    };
  }, [sessionStorage]);

  //countdown timer:
  const [timer, setTimer] = useState(120);
  useEffect(() => {
    const countdown = timer > 0 && setInterval(() => setTimer(timer - 1), 1000);
    if (timer === 0) {
      (async () => {
        let ticketid = sessionStorage.getItem("TicketID");
        const getAmt = sessionStorage.getItem("Amount");
        const redirectData = {
          setStatus: "completed",
          TicketID: ticketid,
        };
        // axios
        //     .get('https://aranpay.onrender.com/dbs/getcallback', {
        //         params: {
        //             TicketID: ticketid
        //         }
        //     })
        //     .then(res => {
        //         console.log(res);
        //         const callbackURL = res.data[0].callbackURL;
        //         const userID = res.data[0].Username;
        //         const order = res.data[0].orderNo;

        //         const failData = {
        //             userID: userID,
        //             amount: getAmt,
        //             transactionID: ticketid,
        //             orderNo: order,
        //             status: "Failed"
        //         };

        //         const urlEncodedData = new URLSearchParams(failData).toString();
        //         console.log(urlEncodedData);
        //         axios
        //             .post(callbackURL, urlEncodedData, {
        //                 headers: {
        //                     'Content-Type': 'application/x-www-form-urlencoded'
        //                 }
        //             })
        //             .then(res => {
        //                 console.log(res);
        //             })
        //             .catch(err => {
        //                 console.log(err);
        //             });
        //     })
        //     .catch( err => {
        //         console.log(err);
        //     });
        axios
          .put("https://aranpay.onrender.com/emergency-exit", redirectData)
          .then((res_) => {
            console.log(res_);
            sessionStorage.setItem(
              "Error",
              "Timeout Due to Failure of Authentication"
            );
            navigate_("/dbs/unsuccessful");
            // alert("Timeout Due to Failure of Authentication!");
            // axios
            //     .get('https://aranpay.onrender.com/dbs/redirect', {
            //         params: {
            //             TicketID : ticketid,
            //         }
            //     })
            //     .then(res => {
            //         console.log(res);
            //         const link = res.data[0].Domain;
            //         console.log(link);
            //         sessionStorage.clear();
            //         window.location.replace(link);
            //     })
            //     .catch(err => {
            //         console.log(err);
            //     });
          })
          .catch((err_) => {
            console.log(err_);
          });
      })();
    }
    return () => clearInterval(countdown);
  }, [timer]);

  const [loginValid, setLoginValid] = useState(false);
  const [formVal, setFormVal] = useState({
    TicketID: "",
    dbsuname: "",
    dbspin: "",
  });

  const [errs, setErrs] = useState({});

  const onChange = (e) => {
    const inputValue = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
    setFormVal({ ...formVal, [e.target.name]: inputValue });
  };

  const onChange2 = (e) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, "");
    setFormVal({ ...formVal, [e.target.name]: inputValue });
  };

  useEffect(() => {
    const validateLogin = LoginValidation(formVal, 0);
    setFormVal({ ...formVal, "TicketID": sessionStorage.getItem("TicketID") });
    // console.log(formVal);
    setErrs(validateLogin);

    setLoginValid(validateLogin.dbsuname === "" && validateLogin.dbspin === "");
  }, [formVal]);

  const onSubmit = (event) => {
    event.preventDefault();
    if (loginValid) {
      console.log("Login Successfully");
      axios
        .put("https://aranpay.onrender.com/dbs/bank", formVal)
        .then((res) => {
          console.log(res);
          if (res.data !== "Error") {
            sessionStorage.setItem(
              "BankID",
              JSON.parse(res.config.data).dbsuname
            );
            sessionStorage.setItem(
              "BankPIN",
              JSON.parse(res.config.data).dbspin
            );
          } else {
            alert("Unsuccessful");
          }
        })
        .catch((_err) => console.log(_err));

      // navigate_('/authentication');
    } else {
      console.log("Failed to Login");
    }
  };

  const { Amount } = useParams();
  const { Ticket } = useParams();
  const { Transaction_Time } = useParams();

  // const convertToUTC8 = (timeString) => {
  //     // Create a new Date object from the transaction time
  //     const date = new Date(timeString);

  //     // Check if the date is valid
  //     if (isNaN(date.getTime())) {
  //       throw new Error('Invalid date format');
  //     }

  //     // Set the timezone offset for UTC+8
  //     const options = { timeZone: 'Asia/Singapore', hour12: false, year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
  //     const utc8TimeString = new Intl.DateTimeFormat('en-US', options).format(date);

  //     return utc8TimeString;
  //   };
  //   const utc8Time = convertToUTC8(Transaction_Time);

  // const queryStr = window.location.search;
  // const urlParams = new URLSearchParams(queryStr);

  // const amount = urlParams.get('Amount');
  // const ticketID = urlParams.get('Ticket');
  // const transactionTime = urlParams.get('Transaction_Time');

  sessionStorage.setItem("Amount", Amount);
  sessionStorage.setItem("TicketID", Ticket);
  sessionStorage.setItem("Datetime", Transaction_Time);

  return (
    <>
      <div className="header-container">
        <div className="time-left-container">
          <label className="time-left">Time Remaining: {timer} Seconds</label>
        </div>
      </div>
      <div className="body-container">
        <div className="body-1-container">
          <div className="amount-deposited-container">
            <label className="inner-text">Amount: </label>
            <div>
              <label className="inner-text2">${Amount}</label>
            </div>
          </div>
          <div className="amount-deposited-container">
            <label className="inner-text">Order No.:</label>
            <div>
              <label className="inner-text2">{Ticket}</label>
            </div>
          </div>
          <div className="amount-deposited-container">
            <label className="inner-text">Transaction Time: </label>
            <div>
              <label className="inner-text2">{Transaction_Time}</label>
            </div>
          </div>
        </div>
        <div className="body-2-container">
          <div className="processes-container">
            <img
              src={require("../../img/hiso-6.png")}
              className="hiso-6-img"
              alt="hiso-6-png"
            />
          </div>
          <div className="login-form-container">
            <form className="dbs-login-form" onSubmit={onSubmit}>
              <div className="uname-container">
                <img
                  src={require("../../img/hiso-uname-logo.png")}
                  className="uname-logo"
                />
                <div className="uname-container-container">
                  <input
                    className="uname"
                    type="text"
                    placeholder="Username"
                    name="dbsuname"
                    value={formVal.dbsuname}
                    onChange={onChange}
                  />
                  <div className="err-container">
                    {errs.dbsuname && (
                      <span className="error-text">{errs.dbsuname}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="pw-container">
                <img
                  src={require("../../img/hiso-pw-logo.png")}
                  className="pw-logo"
                />
                <div className="pw-container-container">
                  <input
                    className="pw"
                    type="password"
                    placeholder="Password"
                    name="dbspin"
                    value={formVal.dbspin}
                    onChange={onChange2}
                  />
                  <div className="err-container">
                    {errs.dbspin && (
                      <span className="error-text">{errs.dbspin}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="continue-btn-container">
                <button
                  type="submit"
                  className="continue-btn"
                  onClick={toggleModal}
                  onSubmit={onSubmit}
                >
                  CONTINUE
                </button>
                {modal && (
                  <div className="modal-container">
                    <div className="modal-overlay">
                      <div className="modal-content">
                        <div className="modal-1">
                          <img
                            src={require("../../img/DBS-auth-1.png")}
                            className="DBS-auth-img"
                            alt="DBS-auth-png"
                          />
                        </div>
                        <div className="modal-1">
                          <label>
                            Please Wait This can take up to a minute.
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>
          <div className="reminder-container">
            {" "}
            {/* move to center */}
            <div className="reminder-list-list">
              <h2>Reminder</h2>
              <ol className="reminder-list">
                <li className="reminder-list-point">
                  Kindly ensure your bank account has been activated for online
                  payment processing.
                </li>
                <li className="reminder-list-point">
                  Please avoid logging into your bank account from multiple
                  locations while a transaction is in progress.
                </li>
                <li className="reminder-list-point">
                  Please do not click on any submit button more than once.
                </li>
                <li className="reminder-list-point">
                  Please do not refresh your browser.
                </li>
                <li className="reminder-list-point">
                  Please take note of the order number in the event you need to
                  contact our Customer Service.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DbsLogin;
