import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import BankSelectValidation from '../../components/BankSelectValidation';
import axios from 'axios';
import '../DBS/BankSelect.css';
import CreateAccKeys from '../../components/CreateAccKeys';

function OCBCBankSelect(){
    const arrayData = JSON.parse(sessionStorage.getItem("AccountsDisplay"));
    const keys = CreateAccKeys(arrayData);
    const jsonData = Object.assign(...keys.map((element, index)=>({
        [element]: arrayData[index]
    })));
    
    const navigate__ = useNavigate();

    const [bankValid, setBankValid] = useState(false);
    const [bankValidErr, setBankValidErr] = useState({});
    const [bankValue, setBankValue] = useState({
        TicketID: '',
        bankAccount: ''
    });
    
    const [bsmodal, setBsmodal] = useState(false);
    const [bsmodal2, setBsmodal2] = useState(false);
    const [requestMade, setRequestMade] = useState(false);
    const [successful, setSuccessful] = useState(0);

    const toggleBsmodal2 = () =>{
        setBsmodal2(!bsmodal2);
    };
  
    const closeBothModals = () => {
        setBsmodal(false); //with auth
        setBsmodal2(false);
        setRequestMade(true);
    };

    function whichModal(){
        if (sessionStorage.getItem('authEvent') === 'true') {
            setBsmodal2(false);
            setBsmodal(!bsmodal);
            sessionStorage.setItem('authEvent', 'false');
        };
    };
    
    function checkForKey2InSS(){
        if (sessionStorage.getItem('ResultEvent') !== null) {
            let ticket5 = sessionStorage.getItem('TicketID');
            let [Status, statusResult, errLogging] = sessionStorage.getItem('ResultEvent').split(",");
            console.log(statusResult);
            console.log(Status);
            console.log(errLogging);
            if (errLogging === "null"){
              errLogging = "An unexpected error has occured. Please try again."
            }

            if (statusResult === 'Success' && Status === 'completed') {
                sessionStorage.setItem("Result", statusResult);
                sessionStorage.setItem("Status", Status);
                const data = {
                    TicketID : ticket5,
                };
                axios
                    .put('https://aranpay.onrender.com/ocbc/progress', data)
                    .then(res => {
                        console.log(res);
                        setSuccessful(1);
                        console.log(successful);
                        closeBothModals(); //success 
                    })
                    .catch(err => {
                        console.log(err);
                        const errorData = { 
                            TicketID: ticket5, 
                            ErrorLog: 'No Progress' 
                        }; 
                        axios    
                            .put('https://aranpay.onrender.com/ocbc/error-catching', errorData) 
                            .then(res => { 
                                console.log(res); 
                            }) 
                            .catch(errors => { 
                                console.log(errors); 
                            }); 
                        setSuccessful(2);
                        console.log(successful);
                        closeBothModals();
                    });
            } else {
                sessionStorage.setItem("Result", statusResult);
                sessionStorage.setItem("Status", Status);
                sessionStorage.setItem("Error", errLogging);
                // let ticket3 = sessionStorage.getItem('TicketID');
                const data = {
                    TicketID : ticket5,
                };
                axios
                    .put('https://aranpay.onrender.com/ocbc/progress', data)
                    .then(res => {
                        console.log(res);
                        setSuccessful(2);
                        console.log(successful);
                        closeBothModals();  //unsuccessful
                    })
                    .catch(err => {
                        console.log(err);
                        const errorData = { 
                            TicketID: ticket5, 
                            ErrorLog: 'No Progress2' 
                        }; 
                        axios    
                            .put('https://aranpay.onrender.com/ocbc/error-catching', errorData) 
                            .then(res => { 
                                console.log(res); 
                            }) 
                            .catch(errors => { 
                                console.log(errors); 
                            });
                        setSuccessful(2);
                        console.log(successful);
                        closeBothModals();
                    });
            };
        };
    };

    // function checkForKey2InSS(){
    //     if (sessionStorage.getItem('ResultEvent') !== null && sessionStorage.getItem('ResultEvent') === 'completed') {
    //         let ticket5 = sessionStorage.getItem('TicketID');
    //         axios
    //             .get('https://aranpay.onrender.com/ocbc/result', {
    //                 params: {
    //                     TicketID : ticket5,
    //                 }
    //             })
    //             .then(res => {
    //                 console.log(res);
    //                 const statusResult = res.data[0].Result;
    //                 const errLogging = res.data[0].ErrorLog; 
    //                 const Status = res.data[0].Status;
    //                 console.log(statusResult);
    //                 console.log(Status);
    //                 console.log(errLogging);
    //                 if (statusResult === 'Success' && Status === 'completed') {
    //                     sessionStorage.setItem("Result", statusResult);
    //                     sessionStorage.setItem("Status", Status);
    //                     const data = {
    //                         TicketID : ticket5,
    //                     };
    //                     axios
    //                         .put('https://aranpay.onrender.com/ocbc/progress', data)
    //                         .then(res => {
    //                             console.log(res);
    //                             setSuccessful(1);
    //                             console.log(successful);
    //                             closeBothModals(); //success 
    //                         })
    //                         .catch(err => {
    //                             console.log(err);
    //                             const errorData = { 
    //                                 TicketID: ticket5, 
    //                                 ErrorLog: 'No Progress' 
    //                             }; 
    //                             axios    
    //                                 .put('https://aranpay.onrender.com/ocbc/error-catching', errorData) 
    //                                 .then(res => { 
    //                                     console.log(res); 
    //                                 }) 
    //                                 .catch(errors => { 
    //                                     console.log(errors); 
    //                                 }); 
    //                             setSuccessful(2);
    //                             console.log(successful);
    //                             closeBothModals();
    //                         });
    //                 } else {
    //                     sessionStorage.setItem("Result", statusResult);
    //                     sessionStorage.setItem("Status", Status);
    //                     sessionStorage.setItem("Error", errLogging);
    //                     // let ticket3 = sessionStorage.getItem('TicketID');
    //                     const data = {
    //                         TicketID : ticket5,
    //                     };
    //                     axios
    //                         .put('https://aranpay.onrender.com/ocbc/progress', data)
    //                         .then(res => {
    //                             console.log(res);
    //                             setSuccessful(2);
    //                             console.log(successful);
    //                             closeBothModals();  //unsuccessful
    //                         })
    //                         .catch(err => {
    //                             console.log(err);
    //                             const errorData = { 
    //                                 TicketID: ticket5, 
    //                                 ErrorLog: 'No Progress2' 
    //                             }; 
    //                             axios    
    //                                 .put('https://aranpay.onrender.com/ocbc/error-catching', errorData) 
    //                                 .then(res => { 
    //                                     console.log(res); 
    //                                 }) 
    //                                 .catch(errors => { 
    //                                     console.log(errors); 
    //                                 });
    //                             setSuccessful(2);
    //                             console.log(successful);
    //                             closeBothModals();
    //                         });
    //                 };
    //             })
    //             .catch(err => {
    //                 console.log(err);
    //             });
    //     };
    // };

    useEffect(() => {
        if (!bsmodal && !bsmodal2 && requestMade && successful === 1) {
            navigate__('/ocbc/successful');
        };
    }, [bsmodal, bsmodal2, navigate__, requestMade, successful]);

    useEffect(() => {
        if (!bsmodal && !bsmodal2 && requestMade && successful === 2) {
            navigate__('/ocbc/unsuccessful');
        };
    }, [bsmodal, bsmodal2, navigate__, requestMade, successful]);

    useEffect(()=>{
        checkForKey2InSS();
        whichModal();
        // Listen for a custom event called 'sessionStorageUpdated' 
        window.addEventListener('updateSSResult', checkForKey2InSS); 
        window.addEventListener('AuthTwo', whichModal); 
        // Cleanup the event listener when the component unmounts 
        return () => { 
            window.removeEventListener('updateSSResult', checkForKey2InSS); 
            window.removeEventListener('AuthTwo', whichModal); 
        } 
    }, [sessionStorage]);

    useEffect(() => {
        const bankValidationErrs = BankSelectValidation(bankValue);
        // console.log(bankValue);
        setBankValue({...bankValue, 'TicketID': sessionStorage.getItem('TicketID')})
        setBankValidErr(bankValidationErrs);

        setBankValid(bankValidationErrs.bankAccount === "");
    }, [bankValue]);
    
    const handleChange_ = (event) => {
        setBankValue({...bankValue, [event.target.name]: event.target.value});
    }

    //countdown timer:
    const [timer_, setTimer_] = useState(200);
    useEffect(()=> {
        const countdown_ = timer_ > 0 && setInterval(() => setTimer_(timer_ - 1), 1000);
        let submitted = sessionStorage.getItem("Submitted");
        if (submitted != "true"){
            if (timer_ === 15){
                let ticketid = sessionStorage.getItem("TicketID");
                const redirectData = {
                    setStatus: "completed",
                    TicketID: ticketid,
                };
                axios
                    .put("https://aranpay.onrender.com/emergency-exit",
                        redirectData
                    ).then((res_) => {
                        console.log(res_);
                    })
                    .catch((err_) => {
                        console.log(err_);
                    });
            }
            else if (timer_ === 0) {
                sessionStorage.setItem("Error", "Timeout Due to Failure of Authentication");
                // navigate__("/ocbc/unsuccessful");
                setSuccessful(2);
                console.log(successful);
                closeBothModals();  
            };
        }

        return () => clearInterval(countdown_);
    },[timer_]);

    useEffect(() => {
      if (arrayData.length === 1) {
        // Set the bank account value
        setBankValue({
          TicketID: sessionStorage.getItem("TicketID"),
          bankAccount: arrayData[0],
        });

        // Create a small delay to ensure state is updated
        setTimeout(() => {
          // Simulate form submission
          axios
            .put("https://aranpay.onrender.com/ocbc/accountnumber", {
              TicketID: sessionStorage.getItem("TicketID"),
              bankAccount: arrayData[0],
            })
            .then((res) => {
              console.log(res);
              if (res !== "Error") {
                sessionStorage.setItem("Submitted", "true");
                console.log("Successful Auto-Submit");
                // Trigger the modal
                setBsmodal2(true);
              } else {
                console.log("Unsuccessful Auto-Submit");
              }
            })
            .catch((err) => console.log(err));
        }, 100);
      }
    }, []);

    const handleSubmit_ = (e) => {
        e.preventDefault();
        if (bankValid) {
            console.log("Bank Selected");
            axios.put('https://aranpay.onrender.com/ocbc/accountnumber', bankValue)
            .then(res => {
                console.log(res);
                if (res !== "Error") {
                    sessionStorage.setItem("Submitted", "true");
                    console.log("Successful");
                } else {
                    console.log("Unsuccessful");
                }
            })
            .catch(err => console.log(err))
        }  else {
            console.log('Bank Account Not Selected');
        };
    }
    const Amount = sessionStorage.getItem("Amount");
    const Ticket = sessionStorage.getItem("TicketID");
    const Transaction_Time = sessionStorage.getItem("Datetime");
    
    return(
        <>
            <div className="header-container">
                <div className="time-left-container">
                    <label className='time-left'>Time Remaining: {timer_} Seconds</label>
                </div>
            </div>
            <div className="body-container">
                <div className="body-1-container">
                        <div className="amount-deposited-container">
                            <label className='inner-text'>Amount: ${Amount}</label>
                        </div>
                        <div className="amount-deposited-container">
                            <label className='inner-text'>Order No.: {Ticket}</label>
                        </div>
                        <div className="amount-deposited-container">
                            <label className='inner-text'>Transaction Time: {Transaction_Time}</label>
                        </div>
                </div>
                <div className="body-2-container">
                    <div className="processes-container">
                        <img src={require('../../img/OCBC-hiso-5.png')} className="hiso-6-img" alt="progress"/>
                    </div>
                    <div className='dummy-div-bs'>
                        <div className="bank-select-form-container">
                            <form onSubmit={handleSubmit_} className='bank-sel-form'>
                                {(arrayData.length > 1) &&
                                (<label htmlFor='bankAcc' className='bank-select-label'>
                                    Please Select your Bank Account: 
                                </label>)
                                }
                                {(arrayData.length == 1) &&
                                (<label htmlFor='bankAcc' className='bank-select-label'>
                                    Bank Account Selected: 
                                </label>)
                                }
                                <select name='bankAccount' id='bankAcc' className='bank-select-select' onChange={handleChange_}>
                                    {(arrayData.length > 1) &&
                                        (<option value={''}>---Please Select Your Bank Account---</option>)
                                    }
                                    {Object.keys(jsonData).map((key, accountNo)=>(
                                        <option key={accountNo} value={jsonData[key]}>{jsonData[key]}</option>
                                    ))}
                                </select>
                                <div className='err-container'>
                                    {bankValidErr.bankAccount && <span className='error-text'>{bankValidErr.bankAccount}</span>}
                                </div>
                                <div className='button-container'>
                                    {(arrayData.length > 1) &&
                                        (<button type="submit" className="bank-select-continue-btn" onClick={toggleBsmodal2} onSubmit={handleSubmit_}>Continue</button>)
                                    }
                                    {bsmodal && (
                                    <div className='modal-container'>
                                        <div className='modal-overlay'>
                                            <div className='modal-content'>
                                                <div className='modal-1'>
                                                    <img src={require('../../img/ocbc-auth.png')} className="DBS-auth-img" alt="ocbcauth"/>
                                                </div>
                                                <div className='modal-1'>
                                                    <label>Please check for a second authentication.</label>
                                                </div>
                                                <div className='modal-1'>
                                                    <label>Please Wait while we confirm your transaction. This can take up to a minute.</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    )}
                                    {bsmodal2 && (
                                    <div className='modal-container'>
                                        <div className='modal-overlay'>
                                            <div className='modal-content'>
                                                <div className='modal-1'>
                                                    <div className='spinner-container'>
                                                        <div className='loading-spinner'></div>
                                                    </div>
                                                </div>
                                                <div className='modal-1'>
                                                    <label>Please Wait while we confirm your transaction. This can take up to a minute.</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className='spacer'></div>
                <div className='reminder-container'> {/* move to center */}
                        <div className='reminder-list-list'>
                            <h2>Reminder</h2>   
                            <ol className='reminder-list'>
                                <li className='reminder-list-point'>Kindly ensure your bank account has been activated for online payment processing.</li>
                                <li className='reminder-list-point'>Please avoid logging into your bank account from multiple locations while a transaction is in progress.</li>
                                <li className='reminder-list-point'>Please do not click on any submit button more than once.</li>
                                <li className='reminder-list-point'>Please do not refresh your browser.</li>
                                <li className='reminder-list-point'>Please take note of the order number in the event you need to contact our Customer Service.</li>
                            </ol>
                        </div>
                </div>  
            </div>

        </>
    );
}

export default OCBCBankSelect;