import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DbsLogin from "./pages/DBS/Login";
import Success from "./pages/DBS/Success";
import BankSelect from "./pages/DBS/BankSelect";
import Fail from "./pages/DBS/Fail";
import OCBCLogin from "./pages/OCBC/Login";
import OCBCSuccess from "./pages/OCBC/Success";
import OCBCBankSelect from "./pages/OCBC/BankSelect";
import OCBCFail from "./pages/OCBC/Fail";
import UOBLogin from "./pages/UOB/Login";
import UOBSuccess from "./pages/UOB/Success";
import UOBBankSelect from "./pages/UOB/BankSelect";
import UOBFail from "./pages/UOB/Fail";
import { io } from "socket.io-client";

// const socket = io('wss://aranpay.onrender.com/');
const socket = io("wss://aranpay.onrender.com", {
  withCredentials: true,
});

function App() {
  socket.on("connect", () => {
    console.log("WebSocket Connected ✅");
    console.log("Socket ID:", socket.id);
  });

  socket.on("disconnect", (reason) => {
    console.log("WebSocket Disconnected ❌");
    console.log("Disconnect reason:", reason);
  });

  socket.on("connect_error", (error) => {
    console.log("WebSocket Connection Error ⚠️");
    console.log("Error details:", error.message);
  });

  socket.on("accDisplayEvent", (message) => {
    if (sessionStorage.getItem("AccountsDisplayEvent") === null) {
      sessionStorage.setItem("AccountsDisplayEvent", message);
      console.log(message);
      const event = new Event("sessionStorageUpdated");
      window.dispatchEvent(event);
    }
  });

  socket.on("resultEvent", (message) => {
    if (sessionStorage.getItem("ResultEvent") === null) {
      console.log(message);
      sessionStorage.setItem("ResultEvent", message);
      const event2 = new Event("updateSSResult");
      window.dispatchEvent(event2);
    }
  });

  socket.on("errEvent", (message) => {
    if (sessionStorage.getItem("ErrorEvent") === null) {
      console.log(message);
      sessionStorage.setItem("ErrorEvent", message);
      const event3 = new Event("error");
      window.dispatchEvent(event3);
    }
  });

  socket.on("AuthTwoEvent", (message) => {
    if (sessionStorage.getItem("authEvent") === null) {
      console.log(message);
      sessionStorage.setItem("authEvent", message);
      const event4 = new Event("AuthTwo");
      window.dispatchEvent(event4);
    }
  });

  socket.on("AuthThreeEvent", (message) => {
    if (sessionStorage.getItem("authEvent2") === null) {
      console.log(message);
      sessionStorage.setItem("authEvent2", "true");
      const event5 = new Event("AuthThree");
      window.dispatchEvent(event5);
    }
  });

  // For emitting startPolling event in Server.js
  window.addEventListener("startPolling", (event) => {
    const ticketNumber = event.detail.ticketNumber; // Access the ticket number from the event detail
    console.log("Received ticket number:", ticketNumber);

    // Emit the ticket number to the server via WebSocket
    socket.emit("startPolling", { ticketNumber: ticketNumber });
  });

  // socket.on("LoginAuthEvent", (message) => {
  //   if (sessionStorage.getItem("LoginAuth") === null) {
  //     console.log(message);
  //     sessionStorage.setItem("LoginAuth", "true");
  //     const event5 = new Event("LoginAuth");
  //     window.dispatchEvent(event5);
  //   }
  // });

  //browser router routes are absolute static pathing, cannot be altered to take in parameters
  return (
    <>
      <div>
        <BrowserRouter>
          <Routes>
            <Route path="/dbs" element={<DbsLogin />}>
              <Route
                path=":Amount/:Ticket/:Transaction_Time"
                element={<DbsLogin />}
              />
            </Route>
            <Route path="/dbs/bank-select" element={<BankSelect />} />
            <Route path="/dbs/successful" element={<Success />} />
            <Route path="/dbs/unsuccessful" element={<Fail />} />
            <Route path="/ocbc" element={<OCBCLogin />}>
              <Route
                path=":Amount/:Ticket/:Transaction_Time"
                element={<OCBCLogin />}
              />
            </Route>
            <Route path="/ocbc/bank-select" element={<OCBCBankSelect />} />
            <Route path="/ocbc/successful" element={<OCBCSuccess />} />
            <Route path="/ocbc/unsuccessful" element={<OCBCFail />} />
            <Route path="/uob" element={<UOBLogin />}>
              <Route
                path=":Amount/:Ticket/:Transaction_Time"
                element={<UOBLogin />}
              />
            </Route>
            <Route path="/uob/bank-select" element={<UOBBankSelect />} />
            <Route path="/uob/successful" element={<UOBSuccess />} />
            <Route path="/uob/unsuccessful" element={<UOBFail />} />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
