function otpValidation(value){
    let error_ = {};

    if (value.OTP === "") {
        error_.otp = "Please fill in the OTP field";
    } else {
        error_.otp = "";
    }
    return error_;
}

export default otpValidation;